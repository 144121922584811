import service from "../../../service/catalog.service";
import { mapState } from "vuex";
import bouncer from "../../../../../helpers/bouncer";

export default {
  mixins: [bouncer],
  name: "Pension",
  data() {
    return {
      path: "pensions",
      confirmDeleteModal: false,
      processing: false,
      submitted: false,
      formDialog: false,
      filters: {},
      items: null,
      creditDebit: null,
      currentItem: null,
      percentage: null,
      settlement_basis: null,
      layout: "list",
      countries: [],
      lastWeek: null,
      isAccrued: false,
      typeRegister: [
        { name: "Liquidacion", code: "1" },
        { name: "Otros", code: "2" },
        { name: "Prestamo", code: "3" },
      ],
      selectedType: null,
    };
  },
  carService: null,
  eventService: null,
  nodeService: null,
  created() {},
  mounted() {
    if (this.canAccess("conceptos_liquidaciones_read")) {
      this.getData();
    }
  },
  methods: {
    saveItem() {
      this.submitted = true;
      if (
        !this.currentItem.name ||
        this.currentItem.name === "" ||
        !this.currentItem.country
      ) {
        return;
      }
      this.processing = true;
      this.formDialog = false;
      this.currentItem.country_id = this.currentItem.country.id;
      this.currentItem.percentage = this.percentage;
      this.currentItem.settlement_basis = this.settlement_basis;
      this.creditDebit === "debito"
        ? (this.currentItem.is_debit = 1)
        : (this.currentItem.is_credit = 1);
      this.creditDebit === "credito"
        ? (this.currentItem.is_credit = 1)
        : (this.currentItem.is_debit = 1);
      this.creditDebit === "debito"
        ? (this.currentItem.is_credit = 0)
        : (this.currentItem.is_debit = 0);
      this.creditDebit === "credito"
        ? (this.currentItem.is_debit = 0)
        : (this.currentItem.is_credit = 0);
      this.lastWeek === true
        ? (this.currentItem.apply_last_week = 1)
        : (this.currentItem.apply_last_week = 0);
      this.isAccrued === true
        ? (this.currentItem.is_accrued = 1)
        : (this.currentItem.is_accrued = 0);
      this.currentItem.type_register = this.selectedType.name;
      service
        .save(this.path, this.currentItem, this.currentItem ? this.currentItem.id : undefined)
        .then(() => {
          this.getData();
          this.$toast.add({
            severity: "success",
            summary: "Registro guardado",
            detail: "Se guardó correctamente",
            life: this.$utils.toastLifeTime(),
          });
        })
        .catch((err) => {
          const message = err.response.data;
          let error = this.$utils.formatError(message);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: error,
            life: this.$utils.toastLifeTime(),
          });
        })
        .finally(() => {
          this.submitted = false;
          this.processing = false;
        });
    },
    hideDialog() {
      this.submitted = false;
      this.currentItem = {};
      this.formDialog = false;
    },
    openNew() {
      this.submitted = false;
      this.currentItem = {};
      this.formDialog = true;
      this.creditDebit = null;
      this.percentage = 0;
      this.settlement_basis = 0;
      this.selectedType = null;
    },
    getData() {
      this.processing = true;
      service
        .get(this.path)
        .then((x) => {
          this.items = x.data;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "se a producido un error",
            life: this.$utils.toastLifeTime(),
          });
        })
        .finally(() => {
          this.processing = false;
        });
    },
    confirmDelete(data) {
      this.currentItem = data;
      this.confirmDeleteModal = true;
    },
    edit(item) {
      this.currentItem = item;
      this.submitted = false;
      this.formDialog = true;
      this.percentage = this.currentItem.percentage;
      this.settlement_basis = this.currentItem.settlement_basis;
      this.currentItem.is_debit === 1
        ? (this.creditDebit = "debito")
        : (this.creditDebit = "credito");
      if (this.currentItem.type_register === "Liquidacion") {
        this.selectedType = this.typeRegister[0];
      } else if (this.currentItem.type_register === "Otros") {
        this.selectedType = this.typeRegister[1];
      } else if (this.currentItem.type_register === "Prestamo") {
        this.selectedType = this.typeRegister[2];
      }
      this.lastWeek = this.currentItem.apply_last_week;
      this.isAccrued = this.currentItem.is_accrued === 1;
    },
    deleteRow() {
      this.processing = true;
      this.confirmDeleteModal = false;
      service
        .delete(this.path, this.currentItem.id)
        .then(() => {
          this.currentItem = {};
          this.$toast.add({
            severity: "success",
            summary: "Registro eliminado",
            detail: "Se eliminó correctamente",
            life: this.$utils.toastLifeTime(),
          });

          this.getData();
        })
        .finally(() => {
          this.processing = false;
        });
    },
    getCatalogue(event, route, field) {
      service.getCatalog(event, route, field).then((x) => {
        this[route] = x;
      });
    },
  },
  computed: {
    ...mapState("auth", ["isAdmin"]),
  },
};
